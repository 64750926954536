import React from 'react'

import { View, WhiteButton } from '@cnd/elements'
import Image from 'next/image'
import { useRouter } from 'next/router'

const OpenMemoriesIndex = () => {
  const router = useRouter()
  const goToMe = () => {
    const phoneNumber = `+447971676841`
    router.push(`/Me/${phoneNumber}`)
  }
  return (
    <View center style={{ height: '100%' }}>
      <Image src={`/images/logo.png`} width={80} height={80} alt="logo" />
      <WhiteButton onClick={goToMe}>Login</WhiteButton>
      <span
        style={{
          fontFamily: 'Libre Baskerville',
          fontSize: 40,
          marginTop: 20,
          color: '#ff5b02',
          marginBottom: 20,
        }}
      >
        open<span style={{ fontWeight: 800 }}>Memories</span>
      </span>
      <a
        href="tel:+447403936430"
        style={{
          textDecoration: 'none',
          color: 'white',
          display: 'inline-block',
          background: '#ff5b02',
          paddingInline: 12,
          paddingBlock: 6,
          paddingBottom: 4,
          borderRadius: 20,
        }}
      >
        <span style={{ fontFamily: 'Hind', fontSize: 20 }}>+44 (0) 7403 93 64 30</span>
      </a>
    </View>
  )
}
export default OpenMemoriesIndex
